<template>
  <in-layout>
    <template slot="list">
      <v-container grid-list-md>

        <pendency-dialog
          v-if="showPendencyDialog"
          :show="showPendencyDialog"
          :selectedLicense = selectedLicense
          @close="closePendencyDialog"
        />

        <confirmation-dialog
          ref="showApprovalDialog"
          title="Deseja aprovar a licença?"
          toApprove
        />

        <confirmation-dialog
          ref="showRefusalDialog"
          width="450"
          title="Deseja recusar a licença?"
          label="Escreva uma justificativa"
          maxlength="500"
        />

        <v-layout column class="ma-2">
          <router-link class="mb-4" style="cursor:pointer" tag="span" :to="{name: 'Configurações'}">
            <v-icon size="18">mdi-chevron-left</v-icon>
            Voltar para configurações
          </router-link>

          <h1>Licenças pendentes</h1>

          <v-layout row align-end>
            <v-flex xs4>
              <v-text-field
                v-model="search"
                name="search"
                label="Pesquisar Licença"
                prepend-inner-icon="mdi-magnify"
                clearable
                @click:clear="typing()"
                @input="typing()"
              />
            </v-flex>

            <v-flex xs3>
              <v-select
                label="Status"
                v-model="status"
                placeholder="Selecionar"
                :menu-props="{ 'offset-y': true }"
                :items="getAllStatus"
                return-object
                @input="typing()"
              >
                <template v-slot:item="{ item }">
                  <v-icon style="margin-top: 4px;" v-if="item.color"
                    size="12"
                    :color="item.color"
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                  <span class="body-2 ml-2"> {{ item.text }} </span>
                </template>
              </v-select>
            </v-flex>

            <v-spacer />

            <v-flex xs3 class="mb-2" v-if="licenses.length">
              <v-layout justify-end align-center>
                Exibindo {{ showingStart }} - {{ showingEnd }} de {{ totalItems }}
                <v-btn
                  icon
                  @click="pageNumber-=1"
                  :disabled="pageNumber <= 1"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="pageNumber+=1"
                  :disabled="pageNumber >= totalPages"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-data-table
          :headers="headers"
          :items="licenses"
          :loading="loading"
          hide-actions
          no-data-text="Nenhuma Empresa com Licença Pendente de Aprovação"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.id }}</td>
            <td>{{ formatCpfCnpj(props.item.person.cpf_cnpj) }}</td>
            <td>{{ props.item.person.name }}</td>
            <td>{{ translateLicenseType(props.item.license_type) }}</td>
            <td>
              <v-icon
                size="17"
                :color="chipStatusColor(props.item.status)"
              >
                mdi-checkbox-blank-circle
              </v-icon>
              {{ translatedStatus(props.item.status) }}
            </td>
            <td class="text-xs-right">
              <v-btn small icon flat>
                <v-icon @click="edit(props.item)">mdi-eye</v-icon>
              </v-btn>
              <v-btn
                small
                icon
                flat
                color="error"
                :loading="loading"
                @click="openRefusalDialog(props.item)"
                v-if="canApproveLicense(props.item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                small
                icon
                flat
                color="success"
                :loading="loading"
                @click="openApprovalDialog(props.item)"
                v-if="canApproveLicense(props.item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-container>
    </template>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import LicenseService from '@/services/license-service';
import ConfirmationDialog from '@/components/settings/ConfirmationDialog.vue';
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import PendencyDialog from '@/components/settings/PendencyDialog.vue';
import isThisMinute from 'date-fns/is_this_minute';

export default {
  name: 'PendencesList',
  components: {
    InLayout,
    ConfirmationDialog,
    PendencyDialog,
  },
  mounted() {
    this.status = {
      text: this.translatedStatus('waiting_approval'),
      color: this.chipStatusColor('waiting_approval'),
      value: 'waiting_approval',
    };
    this.getLicenses(1);
  },
  data() {
    return {
      search: '',
      status: '',
      loading: false,
      licenses: [],
      formatCpfCnpj,
      selectedLicense: {},
      showPendencyDialog: false,
      totalItems: 0,
      totalPages: 0,
      pageNumber: 1,
      itemsPerPage: 20,
      headers: [
        {
          text: 'ID', value: 'id', sortable: false, width: 150,
        },
        {
          text: 'CPF/CNPJ', value: 'CPF_CNPJ', align: 'left', sortable: false, width: 250,
        },
        {
          text: 'Nome', value: 'name', align: 'left', sortable: false, width: 400,
        },
        {
          text: 'Licença', value: 'license', align: 'left', sortable: false, width: 350,
        },
        {
          text: 'Status', value: 'status', align: 'left', sortable: false, width: 400,
        },
        {
          text: 'Ações', value: 'actions', align: 'center', sortable: false, width: 400,
        },
      ],
    };
  },
  methods: {
    edit(license) {
      this.selectedLicense = license;
      this.showPendencyDialog = true;
    },
    closePendencyDialog() {
      this.showPendencyDialog = false;
      this.getLicenses();
    },
    getLicenses(pageNumber) {
      this.pageNumber = pageNumber || this.pageNumber;
      this.loading = true;

      LicenseService
        .getLicenses({
          page: this.pageNumber,
          limit: this.itemsPerPage,
          q: this.search || undefined,
          status: this.status ? this.status.value : 'waiting_approval',
        })
        .then(({ data }) => {
          this.licenses = data.licenses;
          this.totalItems = data.total;
          this.totalPages = data.total_pages;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    approveLicense(license) {
      const licenseId = license.id;
      isThisMinute.loading = true;

      LicenseService
        .approveLicense({
          licenseId,
        })
        .then(() => {
          this.getLicenses();
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refuseLicense(license, reason) {
      const licenseId = license.id;
      this.loading = true;

      LicenseService
        .refuseLicense({
          licenseId,
          reason,
        })
        .then(() => {
          this.getLicenses();
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openApprovalDialog(license) {
      this.$refs.showApprovalDialog.open((e) => {
        this.approveLicense(license);
      });
    },
    openRefusalDialog(license) {
      this.$refs.showRefusalDialog.open((reason) => {
        this.refuseLicense(license, reason);
      });
    },
    canApproveLicense(l) {
      return (l.status === 'waiting_approval' && l.file.url && l.number && l.process_number && l.due_date);
    },
    chipStatusColor(status) {
      return {
        waiting_approval: 'amber',
        waiting_to_be_sent: 'grey',
      }[status] || 'grey';
    },
    translatedStatus(status) {
      return {
        waiting_approval: 'Aguardando Aprovação',
        waiting_to_be_sent: 'Aguardando Envio',
      }[status] || '--';
    },
    translateLicenseType(type) {
      return {
        receiver: 'Destinador',
        transporter: 'Transportador',
        temporary_storage: 'Armazenador Temporário',
      }[type] || '--';
    },
    typing() {
      clearInterval(this.interval);
      this.interval = setTimeout(() => {
        this.getLicenses(1);
      }, 500);
    },
  },
  computed: {
    showingStart() {
      return (this.pageNumber - 1 === 0) ? 1 : (this.pageNumber - 1) * this.itemsPerPage;
    },
    showingEnd() {
      if (this.totalPages === this.pageNumber) {
        return this.totalItems;
      }
      return (this.pageNumber * this.itemsPerPage);
    },
    getAllStatus() {
      const status = ['waiting_approval', 'waiting_to_be_sent'];
      return [
        ...status.map(s => ({
          text: this.translatedStatus(s),
          color: this.chipStatusColor(s),
          value: s,
        })),
      ];
    },
  },
  watch: {
    pageNumber() {
      this.getLicenses(this.pageNumber);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
